import { Box, LinearProgress, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { TFunction } from "next-i18next";
import Link from "next/link";
import React from "react";
import { i18n, Router } from "../../i18n";
import { PointIcon, WalletIcon } from "../../public/img";
import VoucherIcon from "../../public/img/VoucherIcon";
import numberSeparator from "../../src/utils/numberSeparator";
import useStyles from "../../styles/components/CardMembership";
import { GuestMembershipByGroupIdDto } from "../libs/membership";
import { TenantProfileDto } from "../libs/tenant";
import theme from "../theme";

type GetLevelDifferenceFunction = (
  data: GuestMembershipByGroupIdDto["data"]
) => string;

type GetLevelProgressFunction = (
  data: GuestMembershipByGroupIdDto["data"]
) => number;

type CardMembershipDto = {
  readonly t: TFunction;
  isLogin: boolean;
  wallet: number;
  points: number;
  totalVoucher: number;
  membershipData?: GuestMembershipByGroupIdDto["data"];
  tenantProfile: TenantProfileDto;
};

const CardMembership = (props: CardMembershipDto) => {
  const {
    isLogin,
    wallet,
    points,
    totalVoucher,
    membershipData,
    tenantProfile,
    t,
  } = props;
  const classes = useStyles();

  const getLevelDifference: GetLevelDifferenceFunction = (data) => {
    const levelToUp: number =
      data.group.levels[data.level.level + 1].amountToLevelUp;
    const currentLevel: number = data.levelProgress;
    const levelDifference: number = levelToUp - currentLevel;
    return numberSeparator(levelDifference).split(".").join(",");
  };

  const getLevelProgress: GetLevelProgressFunction = (data) => {
    const levelToUp: number =
      data.group.levels[data.level.level + 1].amountToLevelUp;
    const currentLevel: number = data.levelProgress;
    return (currentLevel / levelToUp) * 100;
  };

  return (
    <>
      {isLogin && tenantProfile.membershipConfig ? (
        <Box>
          {tenantProfile?.membershipConfig?.isEnabled && (
            <>
              <Box
                className={classes.groupWrapper}
                onClick={() => Router.push("/membership")}
              >
                <Typography className={classes.groupTypography}>
                  {membershipData?.level?.name}
                </Typography>
                {(membershipData?.level?.level as number) + 1 <
                (membershipData?.group?.levels.length as number) ? (
                  <LinearProgress
                    color="primary"
                    value={getLevelProgress(
                      membershipData as GuestMembershipByGroupIdDto["data"]
                    )}
                    variant="determinate"
                    classes={{
                      root: classes.progressRoot,
                    }}
                  />
                ) : (
                  <LinearProgress
                    color="primary"
                    value={100}
                    variant="determinate"
                    classes={{
                      root: classes.progressRoot,
                    }}
                  />
                )}
                {(membershipData?.level?.level as number) + 1 <
                (membershipData?.group?.levels.length as number) ? (
                  <Typography className={classes.expTypography}>
                    {getLevelDifference(
                      membershipData as GuestMembershipByGroupIdDto["data"]
                    )}{" "}
                    {t("to")}{" "}
                    {
                      membershipData?.group.levels[
                        membershipData.level.level + 1
                      ].name
                    }
                  </Typography>
                ) : (
                  <Typography className={classes.expTypography}>
                    {t("youve-reached-maximum-level")}
                  </Typography>
                )}
              </Box>
            </>
          )}
          <Typography className={classes.titleBalance}>
            Balance and points
          </Typography>
          <Box className={classes.cardContentWrapper}>
            {(tenantProfile?.pointConfiguration?.isEnabled ||
              tenantProfile?.walletConfig?.isEnabled) && (
              <Box className={classes.menuWalletVoucherWrapper}>
                {tenantProfile?.pointConfiguration?.isEnabled &&
                  tenantProfile?.walletConfig?.isEnabled && (
                    <Box
                      className={classes.walletWrapper}
                      onClick={() => Router.push("/balance?page=wallet")}
                    >
                      <Box mr="8px">
                        <WalletIcon />
                      </Box>
                      <Box>
                        <Typography className={classes.walletAmount}>
                          Rp{numberSeparator(wallet)}
                        </Typography>
                        <Typography className={classes.totalPoint}>
                          {numberSeparator(points)} {t("points")}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                {tenantProfile?.pointConfiguration?.isEnabled &&
                  !tenantProfile?.walletConfig?.isEnabled && (
                    <Box
                      className={classes.walletWrapper}
                      onClick={() => Router.push(`/balance?page=point`)}
                    >
                      <Box mr="8px">
                        <PointIcon />
                      </Box>
                      <Box>
                        <Typography className={classes.myPoints}>
                          {t("my-points")}
                        </Typography>
                        <Typography>{numberSeparator(points)}</Typography>
                      </Box>
                    </Box>
                  )}
                {!tenantProfile?.pointConfiguration?.isEnabled &&
                  tenantProfile?.walletConfig?.isEnabled && (
                    <Box
                      className={classes.walletWrapper}
                      onClick={() => Router.push(`/balance?page=wallet`)}
                    >
                      <Box mr="8px">
                        <WalletIcon />
                      </Box>
                      <Box>
                        <Typography className={classes.myPoints}>
                          {t("my-balance")}
                        </Typography>
                        <Typography>Rp{numberSeparator(wallet)}</Typography>
                      </Box>
                    </Box>
                  )}
              </Box>
            )}
            <Box className={classes.menuWalletVoucherWrapper}>
              <Box
                className={classes.walletWrapper}
                onClick={() => Router.push("/vouchers")}
              >
                <Box mr="8px">
                  <VoucherIcon />
                </Box>
                {!tenantProfile?.pointConfiguration?.isEnabled &&
                !tenantProfile?.walletConfig?.isEnabled ? (
                  <Box>
                    <Typography className={classes.voucher}>Voucher</Typography>
                    <Typography
                      variant="caption"
                      className={classes.totalVoucher}
                    >
                      {totalVoucher}{" "}
                      {i18n.language === "en"
                        ? totalVoucher > 1
                          ? "Vouchers"
                          : "Voucher"
                        : "Voucher"}
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Typography
                      style={{
                        color:
                          tenantProfile?.pointConfiguration?.isEnabled &&
                          tenantProfile?.walletConfig?.isEnabled
                            ? "#25282B"
                            : theme.palette.primary.main,
                        fontWeight:
                          tenantProfile?.pointConfiguration?.isEnabled &&
                          tenantProfile?.walletConfig?.isEnabled
                            ? 700
                            : 400,
                        fontSize: 16,

                        marginBottom: 1,
                      }}
                    >
                      Voucher
                    </Typography>
                    <Typography
                      style={{
                        color:
                          tenantProfile?.pointConfiguration?.isEnabled &&
                          tenantProfile?.walletConfig?.isEnabled
                            ? "#A8ABAD"
                            : "#25282B",
                        fontWeight:
                          tenantProfile?.pointConfiguration?.isEnabled &&
                          tenantProfile?.walletConfig?.isEnabled
                            ? 400
                            : 700,
                        fontSize: 14,
                      }}
                    >
                      {totalVoucher}{" "}
                      {i18n.language === "en"
                        ? totalVoucher > 1
                          ? "Vouchers"
                          : "Voucher"
                        : "Voucher"}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Link href="/login">
          <Box onClick={() => localStorage.setItem("from", "home")}>
            <Box className={classes.becomeMemberCard}>
              <Box>
                <Typography className={classes.becomeMemberTitle}>
                  {t("become-member")}
                </Typography>
                <Typography className={classes.becomeMemberSubtitle}>
                  {t("get-a-lot-rewards-by-joining")}
                </Typography>
              </Box>
              <ChevronRightIcon
                classes={{
                  root: classes.chevronRight,
                }}
              />
            </Box>
          </Box>
        </Link>
      )}
    </>
  );
};
export default CardMembership;
