import { Box, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles/profile";

type MenuItemType = {
  text: string;
  onClick?: () => void;
  icon?: JSX.Element;
  suffix?: JSX.Element;
};

const MenuItem = (props: MenuItemType) => {
  const classes = useStyles(props);
  const { text, onClick, icon, suffix } = props;

  return (
    <div className={classes.menuItem} onClick={onClick}>
      <Box className={classes.rightContent}>
        {icon}
        <Typography>{text}</Typography>
      </Box>
      {suffix}
    </div>
  );
};

export default MenuItem;
