import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContentWrapper: { display: "flex", alignItems: "center" },
    menuWalletVoucherWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "50%",
      cursor: "pointer",
    },
    walletWrapper: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
    },
    walletAmount: {
      fontWeight: 700,
      marginBottom: 1,
    },
    totalPoint: {
      color: "#25282B",
      fontSize: 14,
    },
    myPoints: {
      marginBottom: 1,
      color: theme.palette.primary.main,
    },
    voucher: {
      color: "#25282B",
      fontWeight: 700,
      marginBottom: 1,
    },
    totalVoucher: {
      color: "#A8ABAD",
      fontSize: 14,
    },
    groupWrapper: {
      width: "100%",
      marginBottom: 24,
    },
    groupTypography: {
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.primary.main,
      marginBottom: 4,
    },
    expTypography: {
      fontSize: 14,
      color: "#A8ABAD",
      marginTop: 4,
      fontWeight: 400,
    },
    chevronRight: {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
    progressRoot: {
      backgroundColor: "#E8E8E8 !important",
      height: "5px !important",
      borderRadius: "2px",
    },
    becomeMemberCard: {
      padding: "10px 16px !important",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    becomeMemberTitle: {
      fontSize: 14,
      fontWeight: 600,
    },
    becomeMemberSubtitle: {
      color: "#a0a4a8",
      fontSize: 12,
    },
    titleBalance: {
      fontSize: 14,
      fontWeight: 700,
      color: "#929292",
      marginBottom: 8,
    },
  })
);

export default useStyles;
