import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
      backgroundColor: "#ffffff",
      paddingBottom: 80,
      minHeight: "100vh",
    },
    profileTitle: {
      padding: 16,
      position: "fixed",
      zIndex: 2,
      maxWidth: 444,
      width: "100%",
      background:
        "linear-gradient(251.76deg, #F0AB1A 10.34%, #D59209 83.97%, #ECA614 108.97%)",
    },
    avatar: {
      height: "75px !important",
      width: "75px !important",
    },
    badge: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
    },
    badgeIcon: {
      padding: 5,
      color: "#ffffff",
    },
    detailProfileWrapper: {
      padding: 16,
      display: "flex",
      alignItems: "center",
      background:
        "linear-gradient(251.76deg, #F0AB1A 10.34%, #D59209 83.97%, #ECA614 108.97%)",
    },
    divider: {
      height: 8,
      width: "100%",
      backgroundColor: "#f4f4f4",
    },
    menuItem: {
      padding: "16px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
    },
    rootCard: {
      margin: "auto",
      marginTop: 16,
      width: "93%",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1) !important",
      borderRadius: "8px !important",
    },
    menuWrapper: {
      backgroundColor: "#ffffff",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "50%",
      padding: "15px 15px 7px 15px !important",
    },
    labelProfile: {
      fontWeight: 700,
      fontSize: "18px",
      color: "#FFFFFF",
    },
    membershipWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 15px 15px 15px",
    },
    groupWrapper: {
      width: "100%",
      maxWidth: "340px",
    },
    rightContent: {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
  })
);

export default useStyles;
