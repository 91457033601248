import { axiosInstance } from "../config/axios";

export const getGuestTotalBalances = async () => {
  const response = await axiosInstance.get(`/guest/wallets`);
  return response;
};

export const cancelWalletOrder = async (id: any) => {
  const response = await axiosInstance.put(
    `/guest/wallets/orders/${id}/status-cancel`
  );
  return response;
};

// * get total wallet type
export interface GetTotalWalletResponse {
  data: GetTotalWalletData;
  meta: GetTotalWalletMeta;
}
export interface GetTotalWalletData {
  total_balances: number;
}
export interface GetTotalWalletMeta {
  message: string;
  status_code: number;
}

// * get total point type
export interface GetTotalPointResponse {
  data: GetTotalPointData;
  meta: GetTotalPointMeta;
}
export interface GetTotalPointData {
  totalPoints: number;
}
export interface GetTotalPointMeta {
  message: string;
  status_code: number;
}

// * get list wallet type
export interface GetListWalletRequestParams {
  page: string;
  orderBy?: string;
}
export interface GetListWalletResponse {
  data: GetListWalletData[];
  meta: GetListWalletMeta;
}
export interface GetListWalletData {
  title: string;
  amount: number;
  createdAt: string;
  createdAtGMT: Date;
  createdBy: any;
  description: string;
  event: string;
  id: string;
  mutationType: string;
  guest: string;
  tenant: string;
  updatedAt: string;
  updatedAtGMT: Date;
  totalBalances: number;
}
export interface GetListWalletMeta {
  page: number;
  perPage: number;
  totalData: number;
  message: string;
  statusCode: number;
  total_page: number;
  createdAtGMT: string;
}

// * get list point type
export interface GetListPointRequestParams {
  page: string;
  perPage: string;
}
export interface GetListPointResponse {
  data: GetListPointData[];
  meta: GetListPointMeta;
}
export interface GetListPointData {
  id: string;
  title: string;
  totalPoints: number;
  amount: number;
  mutationType: string;
  description: string;
  event: string;
  guest: string;
  tenant: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: any;
  createdAtGMT: string;
}
export interface GetListPointMeta {
  totalData: number;
  page: number;
  perPage: number;
  message: string;
  statusCode: number;
  total_page: number;
}

export const getTotalWallet = async (): Promise<GetTotalWalletResponse> => {
  const response: GetTotalWalletResponse = await axiosInstance.get(
    "/guest/wallets"
  );
  return response;
};

export const getTotalPoint = async (): Promise<GetTotalPointResponse> => {
  const response: GetTotalPointResponse = await axiosInstance.get(
    "/guest/points"
  );
  return response;
};

export const getListWallet = async (
  params: GetListWalletRequestParams
): Promise<GetListWalletResponse> => {
  const { page = "1", orderBy = "desc" } = params;
  const response: GetListWalletResponse = await axiosInstance.get(
    "/guest/wallets/mutations",
    {
      params: {
        page,
        orderBy,
      },
    }
  );
  return response;
};

export const getListPoint = async (
  params: GetListPointRequestParams
): Promise<GetListPointResponse> => {
  const { page = "1", perPage = "10" } = params;
  const response: GetListPointResponse = await axiosInstance.get(
    "/guest/points/mutations",
    {
      params: {
        page,
        perPage,
      },
    }
  );
  return response;
};
